<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="CreditCardIcon"/>
              <span>Form Bilgileri</span>
            </template>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Talep Türü" rules="required">
                  <b-form-group label="* Talep Türü" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.advanceFormTypeId" :options="advanceFormTypeOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Avans Nedeni" rules="required">
                  <b-form-group label="* Avans Nedeni" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.advanceFormReasonTypeId" :options="advanceFormReasonTypeOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6" v-if="bulkForm">
                <validation-provider #default="validationContext" name="Onay Süreci Kontrolü Atlansın">
                  <b-form-group label="Onay Süreci Kontrolü Atlansın" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.bypassWorkflowCheck" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>
            <div class="d-flex">
              <feather-icon icon="CreditCardIcon" size="16"/>
              <h5 class="mb-0 ml-50">Tutar Bilgileri</h5>
            </div>
            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Tutar" rules="required">
                  <b-form-group label="* Tutar" :state="getValidationState(validationContext)">
                    <b-form-input trim placeholder="Tutar" v-model="dataInfo.total"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Para Birimi" rules="required">
                  <b-form-group label="* Para Birimi" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.currencyTypeId" :options="currencyTypeOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex" v-if="dataInfo.advanceFormTypeId === 'INSTALLMENT'">
              <feather-icon icon="CalendarIcon" size="16"/>
              <h5 class="mb-0 ml-50">Taksit Bilgileri</h5>
            </div>
            <b-row class="mt-1 mb-1" v-if="dataInfo.advanceFormTypeId === 'INSTALLMENT'">

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Taksit Sayısı" rules="required">
                  <b-form-group label="* Taksit Sayısı" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.advanceFormInstallmentId" :options="advanceFormInstallmentOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex">
              <feather-icon icon="CalendarIcon" size="16"/>
              <h5 class="mb-0 ml-50" v-if="dataInfo.advanceFormTypeId !== 'INSTALLMENT'">Ödeme Tarihi</h5>
              <h5 class="mb-0 ml-50" v-if="dataInfo.advanceFormTypeId === 'INSTALLMENT'">Ödeme Başlangıç Tarihi</h5>
            </div>
            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Ay" rules="required">
                  <b-form-group label="* Ay" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.paymentMonth" :options="paymentMonthOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Yıl" rules="required">
                  <b-form-group label="* Yıl" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.paymentYear" :options="paymentYearOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex">
              <feather-icon icon="InfoIcon" size="16"/>
              <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
            </div>
            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Açıklama">
                  <b-form-group label="Açıklama">
                    <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="2"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

          </b-tab>
          <b-tab v-if="bulkForm">
            <template #title>
              <feather-icon icon="UsersIcon"/>
              <span>Çalışan Bilgileri</span>
            </template>

            <b-alert variant="warning" show class="mb-1">
              <div class="alert-body font-small-2">
                <p>
                  <small class="mr-50"><span class="font-weight-bold">Sadece seçilen çalışanlar için kayıt oluşturulacaktır.</span></small>
                </p>
              </div>
            </b-alert>

            <b-button variant="outline-secondary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="selectRecords(true)">
              <feather-icon icon="CheckIcon" size="16"/>
              <span class="align-middle"> Tümünü Seç</span>
            </b-button>
            <b-button variant="outline-secondary" @click="selectRecords(false)">
              <feather-icon icon="XCircleIcon" size="16"/>
              <span class="align-middle" role="button"> Tümünü Kaldır</span>
            </b-button>

            <b-card no-body class="border mt-1">
              <b-table
                  :items="dataInfo.users"
                  :fields="userFields"
                  class="mb-0"
                  striped responsive bordered hover>
                <template #cell(profilePicture)="data">
                  <b-avatar
                      ref="previewEl"
                      :src="getApiFile(data.value)"
                      :text="avatarText(data.item.name)"
                      :variant="`light-primary`"
                      size="30px"
                  />
                </template>
                <template #cell(selection)="data">
                  <b-form-checkbox :checked="data.value" @change="changeSelectUserValue(data.item)"/>
                </template>
              </b-table>
            </b-card>
          </b-tab>
        </b-tabs>
        <action-buttons :back-route="'form-advance-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, VBPopover, VBTooltip, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox, BMedia, BMediaAside, BMediaBody, BFormFile, BAlert, BTable, BButton
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@core/utils/validations/validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/form/advance/store"
import userModule from "@/views/organization/employee/store"
import definitionModule from "@/views/system/definition/store"
import flatpickr from "vue-flatpickr-component/src/component"

export default {
  components: {
    BButton,
    BTable, BAlert,
    BFormFile,
    BMediaBody,
    BMediaAside,
    BMedia,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,
    VBPopover,
    VBTooltip,

    flatpickr,
    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const bulkForm = ref(false)

    const dataInfo = ref({
      id: 0,
      userId: JSON.parse(localStorage.getItem('userData')).id,
      advanceFormTypeId: null,
      total: null,
      currencyTypeId: null,
      advanceFormReasonTypeId: null,
      advanceFormInstallmentId: null,
      paymentMonth: null,
      paymentYear: null,
      description: '',
      bypassWorkflowCheck: false,
      users: []
    })

    busy.value = true
    const advanceFormTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'ADVANCE_FORM_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        advanceFormTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.advanceFormTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const currencyTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'CURRENCY_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        currencyTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.currencyTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const advanceFormReasonTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'ADVANCE_FORM_REASON_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        advanceFormReasonTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.advanceFormReasonTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const advanceFormInstallmentOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'ADVANCE_FORM_INSTALLMENT'}).then(response => {
      response.data.data.forEach((value, index) => {
        advanceFormInstallmentOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.advanceFormInstallmentId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })


    const onSubmit = () => {
      const formData = new FormData();
      formData.append('id', dataInfo.value.id);
      formData.append('userId', dataInfo.value.userId);
      formData.append('advanceFormTypeId', dataInfo.value.advanceFormTypeId);
      formData.append('total', dataInfo.value.total);
      formData.append('currencyTypeId', dataInfo.value.currencyTypeId);
      formData.append('advanceFormReasonTypeId', dataInfo.value.advanceFormReasonTypeId);
      formData.append('advanceFormInstallmentId', dataInfo.value.advanceFormInstallmentId);
      formData.append('paymentMonth', dataInfo.value.paymentMonth);
      formData.append('paymentYear', dataInfo.value.paymentYear);
      formData.append('description', dataInfo.value.description);
      formData.append('bypassWorkflowCheck', dataInfo.value.bypassWorkflowCheck);

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : (bulkForm.value ? 'bulkItem' : 'addItem')), (bulkForm.value ? dataInfo.value : formData)).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'form-advance-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'form-advance-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    } else {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      bulkForm.value = params.get("bulk") === 'true';
      if (bulkForm.value) {
        dataInfo.value.bypassWorkflowCheck = true;
      }
    }

    busy.value = true
    store.dispatch('store-user/fetchItems', {
      sortColumn: 'firstName',
      sortDesc: false,
    }).then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive && bulkForm.value) {
          if (!dataInfo.value.users.some(e => e.userId === value.id)) {
            dataInfo.value.users.push({
              userId: value.id,
              name: value.firstName + ' ' + value.lastName,
              profilePicture: value.profilePicture,
              selection: false,
            })
          }
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const paymentMonthOptions = [
      {label: 'Ocak', value: 1},
      {label: 'Şubat', value: 2},
      {label: 'Mart', value: 3},
      {label: 'Nisan', value: 4},
      {label: 'Mayıs', value: 5},
      {label: 'Haziran', value: 6},
      {label: 'Temmuz', value: 7},
      {label: 'Ağustos', value: 8},
      {label: 'Eylül', value: 9},
      {label: 'Ekim', value: 10},
      {label: 'Kasım', value: 11},
      {label: 'Aralık', value: 12},
    ]

    const paymentYearOptions = [
      {label: '2023', value: 2023},
      {label: '2024', value: 2024},
      {label: '2025', value: 2025},
      {label: '2026', value: 2026},
      {label: '2027', value: 2027},
      {label: '2028', value: 2028},
      {label: '2029', value: 2029},
      {label: '2030', value: 2030},
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const userFields = [
      {key: 'profilePicture', label: 'Profil', thStyle: {width: "5%"}},
      {key: 'name', label: 'Ad Soyad'},
      {key: 'selection', label: 'Seçim', thStyle: {width: "10%"}}
    ];

    const changeSelectUserValue = (target) => {
      dataInfo.value.users.forEach(function callback(value, index) {
        if (value.userId === target.userId) {
          value.selection = !target.selection
        }
      })
    }

    const selectRecords = (selection) => {
      dataInfo.value.users.forEach(function callback(value, index) {
        value.selection = selection
      })
    }

    return {
      busy,
      dataInfo,
      refFormObserver,
      advanceFormTypeOptions,
      currencyTypeOptions,
      advanceFormReasonTypeOptions,
      advanceFormInstallmentOptions,
      paymentMonthOptions,
      paymentYearOptions,
      userFields,
      bulkForm,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      changeSelectUserValue,
      selectRecords,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/advanceforms', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/advanceforms/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/advanceforms/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/advanceforms', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/advanceforms', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/advanceforms/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/advanceforms/cancel/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        bulkItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/advanceforms/bulk', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        export(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/advanceforms/export', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
